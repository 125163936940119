"use client"
import { ModeToggle } from "@/app/components/DarkMode"
import { cn } from "@/app/lib"
import Head from "next/head"

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/app/components/ui/navigation-menu"
import { PARSLogo } from "@/app/components/ui/parsLogo"

const Header = () => {
  return (
    <>
      <Head>
        <title>PARS</title>
      </Head>

      <NavigationMenu className="z-20 h-20 max-h-20 w-full max-w-full flex-none justify-between bg-nav-background text-nav-text dark:bg-nav-background-dark dark:text-nav-text-dark">
        <NavigationMenuList>
          <NavigationMenuItem>
            <PARSLogo />
          </NavigationMenuItem>
        </NavigationMenuList>
        <NavigationMenuList className="self-end">
          <NavigationMenuItem>
            <ModeToggle />
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </>
  )
}

export default Header
